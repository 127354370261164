// Remove input arrows 

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.kWAsOx {
  display: flex;
}

.ldeDAM {
  flex: none;
}

.jss10 {
  overflow: hidden;
}

.cQLlaq {
  display: none !important;
}

.makeStyles-drawerContainer-10 {
  overflow: hidden !important;
}

.makeStyles-drawerContainer-22 {
  overflow: hidden !important;
}

.MuiButton-startIcon {
  margin-right: 0px;
  margin-left: 0px;
}

.ant-picker-dropdown {
  z-index: 9999999 !important;
}

.ant-picker-dropdown-placement-bottomLeft {
  z-index: 9999999 !important;
}

thead[class*="ant-table-thead"] th {
  // font-weight: 800 !important;
  // background-color: rgba(85, 85, 85, 0.3) !important;

  font-weight: 800 !important;
  background-color: #fff !important;
}

.ant-table-content {
  border-radius: 10px !important;
}

.rdt_TableCol {
  font-weight: 800 !important;
}

.makeStyles-drawerContainer-19 {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.rdt_TableHeader {
  padding: 25px !important;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.data-table {
  position: relative;
  .table-search-icon {
    position: absolute;
    top: 40%;
    left: 0.75%;
  }
  #table-search {
    border-radius: 50px;
    width: 25%;
    height: 42px;
    margin-bottom: 20px;
    padding-left: 36px;

    &::-webkit-input-placeholder {
      padding-left: 4px;
    }

    &:-ms-input-placeholder {
      padding-left: 4px;
    }

    &::placeholder {
      padding-left: 4px;
    }
  }
}

.footer {
  display: none;
}

.img-container {
  // Media Preview
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .media-preview-container {
    display: flex;
    flex-direction: column;
    .media-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 180px;
      width: 180px;
      margin: 20px 0;
      .media-preview-img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        padding: 20px;
      }

      .media-remove-icon {
        cursor: pointer;
      }
    }
  }

  // Media
  .media-container {
    display: flex;
    flex-direction: column;

    .media-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 180px;
      width: 180px;
      margin: 20px 10px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 5px;

      .media-img {
        // object-fit: contain;
        // height: 100%;
        // width: 100%;
        // border-radius: 5px;
        // object-fit: cover;
        // padding: 20px;
        float: left;
        width: 150px;
        height: 140px;
        object-fit: contain;
      }

      .media-remove-icon {
        cursor: pointer;
      }
    }
  }
}

.draggable-body-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// React data table heading
// .sc-bjUoiL {}
.kWAsOx {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #495057 !important;
}

.text-gray {
  color: #7f7f7f;
}

.ant-picker {
  height: 42px;
  border-radius: 25px !important;
}

.css-26l3qy-menu {
  z-index: 999999 !important;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.sc-dmRaPn {
  ::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 4px !important;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #888 !important;
    border-radius: 16px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
}

#googleMaps {
  height: 500px;

  .googleMaps-container {
    position: relative;
    z-index: 9999;
  }

  .list-group {
    position: absolute;
  }
}

.cms-loading {
  position: absolute;
  font-size: 5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
}

table.fixed {
  table-layout: fixed;
}
td.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}